import { createGlobalStyle } from "styled-components"
import headerfontbold from "../assets/fonts/neuehaasdisplaybold-webfont.woff"
import headerfont from "../assets/fonts/neuehaasdisplaymediu-webfont.woff"
import bodyfontroman from "../assets/fonts/neuehaasdisplayroman-webfont.woff"

const Typography = createGlobalStyle`

@font-face {
    font-family: 'Neue Haas Bold';
    src: url(${headerfontbold});
  }
  @font-face {
    font-family: 'Neue Haas Medium';
    src: url(${headerfont});
  }
  @font-face {
    font-family: 'Neue Haas Roman';
    src: url(${bodyfontroman});
  }


  html {
    font-family: 'Neue Haas Medium', 'Helvetica Neue', sans-serif;
    color: var(--mfblack);
  }
  p, li {
  
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: 'Neue Haas Bold', Impact, 'Helvetica Neue', sans-serif;
    font-weight: normal;
    margin: 0;
    line-height:1
  }
  a {
    color: var(--mfblack);
    
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }
  mark, .mark {
    background: var(--yellow);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

  p{
	letter-spacing:0;
}
strong{
  font-family: 'IBM Semi-Bold 600',  sans-serif;
}


  .l {
    font-family: 'IBM Light 300',  sans-serif;
  }
  .l-i {
    font-family: 'IBM Light 300 Italic',  sans-serif;
  }
  .sb {
    font-family: 'IBM Semi-Bold 600',  sans-serif;
  }
  .sb-i {
    font-family: 'IBM Semi-Bold 600 Italic',  sans-serif;
  }
  .b {
    font-family: 'IBM Bold 700',  sans-serif;
  }
 
`

export default Typography
