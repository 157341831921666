import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Button from "./button/Button"
import MenuButton from "./button/MenuButton"
import styled from "styled-components"
import logo from "../assets/images/logo.png"
import DesktopMenu from "./DesktopMenu/DesktopMenu"

const HeaderStyles = styled.div`
  width: 100%;
  height: 80px;

  position: fixed;
  display: flex;
  align-items: center;
  padding: 10px 0;
  top: 0;
  color: var(--wowwhite);
  font-size: 24px;
  font-family: "Neue Haas Bold";
  text-transform: uppercase;
  z-index:30;
  .hideDesktop{
    display:none;
    @media (min-width: 768px) {
   display:block;
  }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  li {
    list-style: no
    margin: 0;
    padding: 0;ne;
    display: inline;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    position: relative;
    .logo {
      width: 200px;
      position: absolute;
      top: -40px;
      left:100px;
    }
  }
  .center {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    position: relative;
    .logo {
      width: 200px;
      position: absolute;
      top: -40px;
    }
  }
  .right {
    flex: 1;
    display: flex;
    align-items:center;
    justify-content: flex-end;
    padding-right: 20px;
  
  }
`

export default function Header({ siteTitle, location, colour, setColour }) {
  const [mainmenuOpen, setmainmenuOpen] = useState(false)
  var className = mainmenuOpen ? "open" : "closed"
  var menuText = mainmenuOpen ? "Close" : "Menu"
  const handleMainMenuClick = e => {
    setmainmenuOpen(!mainmenuOpen)
    className = mainmenuOpen ? "open" : "closed"
  }

  return (
    <>
      <HeaderStyles>
        <div className="center"></div>
        <div className="right">
          <div className="links">
            <ul></ul>
          </div>
          <div className="hideDesktop">
            <Button linktext="TICKETS (TBA)" bgColour="gold" />
          </div>

          <span onClick={handleMainMenuClick}>
            <MenuButton>{menuText}</MenuButton>
          </span>
        </div>
      </HeaderStyles>

      <DesktopMenu
        open={className}
        mainmenuOpen={mainmenuOpen}
        setmainmenuOpen={setmainmenuOpen}
      />
    </>
  )
}
