/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import React, { useState, useEffect, useStaticQuery } from "react"
import Header from "./header"
import Footer from "./footer/footer"
import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"
import "normalize.css"

//import Typography from "../styles/Typography"

const Layout = ({ children }) => {
  const [menuOpen, setmenuOpen] = useState(false)
  const handleMenuClick = event => {
    setmenuOpen("closed")
  }
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
