import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const ButtonStyles = styled.div`
  font-size: 24px;
  padding: 0px 10px;
  min-width: 120px;
  height: 50px;
  background: var(--wowblack);
  color: var(--mfwhite);
  display: inline-block;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s cubic-bezier(0.42, 0, 0.58, 1) 0s;
  font-family: "Neue Haas Bold";
  text-transform: uppercase;

  margin: 0px 10px;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background: #525151;
    color: white;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.95);
    color: white;
  }
`

export default function Button({ linktext, children }) {
  return (
    <ButtonStyles className={"button "}>
      {linktext}
      {children}
    </ButtonStyles>
  )
}
