import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import logocircle from "../../assets/images/logocircle.png"
import globe from "../../assets/images/SVG/wowsymbol3.svg"

const DesktopMenuStyles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: block;

  transform: translateY(0%);
  height: 100vh;
  transition: transform cubic-bezier(1, 0.7, 0, 1) 0.33s;
  z-index: 25;
  overflow: hidden;
  background: var(--wowgold);
  &.closed {
    transform: translateY(-100%);
  }
  .logo {
    position: absolute;
    width: 40vh;
    height: 40vh;
    top: 50%;

    transform: translateY(-50%);
  }
  .bg {
    position: absolute;
    width: 100%;
    height: 100vh;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    position: absolute;
    padding: 0 32px;
    z-index: 2;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ul {
      margin: 0;
      padding: 0;

      li {
        font-size: 1.8em;
        list-style: none;
        text-transform: uppercase;
        line-height: 2;
        display: inline-block;
        position: relative;
        .comingsoon {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 13px;
          text-transform: uppercase;
          text-align: center;
        }
        &.crossout {
          opacity: 0.4;
          :after {
            content: "";
            display: block;
            width: 100%;
            height: 50%;
            position: absolute;
            top: 0;
            left: 0;
            border-bottom: 3px solid;
          }
        }
        a,
        .link {
          padding: 5px;
          margin: 0px 15px;
          font-size: 45px;

          font-family: "Neue Haas Bold";
        }
      }
    }
  }
`

export default function DesktopMenu({ mainmenuOpen, setmainmenuOpen, open }) {
  const className = mainmenuOpen ? "open" : "closed"
  const handleMainMenuClick = event => {
    setmainmenuOpen(!mainmenuOpen)
  }

  return (
    <>
      <DesktopMenuStyles className={className}>
        <div className="bg">
          <img src={globe} alt="" />
        </div>
        <menu>
          <ul>
            <li className="b" onClick={event => handleMainMenuClick(event)}>
              <Link to="/" activeClassName="active">
                Home
              </Link>
            </li>
            <li className="b crossout">
              <div className="comingsoon">COMING SOON</div>
              <div className="link " to="/info" activeClassName="active">
                Program
              </div>
            </li>
            <li className="b crossout">
              <div className="comingsoon">COMING SOON</div>
              <div className="link " to="/info" activeClassName="active">
                FAQ
              </div>
            </li>
          </ul>
        </menu>
      </DesktopMenuStyles>
    </>
  )
}
