import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const ButtonStyles = styled.div`
  font-size: 24px;
  padding: 0px 10px;
  min-width: 120px;
  height: 50px;
  background: var(--wowblack);
  color: var(--mfwhite);
  display: inline-block;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s cubic-bezier(0.42, 0, 0.58, 1) 0s;
  font-family: "Neue Haas Bold";
  text-transform: uppercase;
  border: 1px solid var(--wowblack);
  margin: 0px 10px;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background: #525151;
    color: white;
  }

  &.gold {
    background: var(--wowgold);
    color: var(--wowblack);
    &:hover {
      filter: brightness(95%);
      background: var(--wowgold);
      color: var(--wowblack);
    }
  }

  &.white {
    background: var(--wowwhite);
    color: var(--wowblack);
    &:hover {
      filter: brightness(95%);
      background: var(--wowwhite);
      color: var(--wowblack);
    }
  }

  &.hovyellow {
    background: var(--mfyellow);
    color: var(--wowblack);
    &:hover {
      background: var(--mfyellow);
      color: var(--wowblack);
      filter: brightness(95%);
    }
  }

  &.hovgreen {
    background: var(--mfgreen);
    color: var(--wowblack);
    &:hover {
      background: var(--mfgreen);
      color: var(--wowblack);
      filter: brightness(95%);
    }
  }

  &.hovblue {
    background: var(--mfblue);
    color: var(--wowblack);
    &:hover {
      background: var(--mfblue);
      color: var(--wowblack);
      filter: brightness(95%);
    }
  }
  &.hovpurple {
    background: var(--mfpurple);
    color: var(--wowblack);
    &:hover {
      background: var(--mfpurple);
      color: var(--wowblack);
      filter: brightness(95%);
    }
  }
`

export default function Button({ linktext, linkto, bgColour, children }) {
  return (
    <>
      <Link to={linkto}>
        <ButtonStyles className={"button " + bgColour}>
          {linktext}
          {children}
        </ButtonStyles>
      </Link>
    </>
  )
}
