import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import symbol from "../../assets/images/SVG/wowsymbol3.svg"
const FooterStyles = styled.div`
  margin-top: 200px;
  .wrapper {
    padding: 20px;
    padding-top: 50px;

    max-width: 1280px;
    margin: 50px auto;
    color: var(--wowwhite);
    font-family: "Neue Haas Roman";
    display: flex;
    position: relative;
    padding-top: 300px;
    .footerlinks {
      flex: 1;
    }
    .footersocials {
      flex: 2;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .symbol {
    width: 200px;
    @media (min-width: 768px) {
      width: 300px;
    }
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`

export default function Footer() {
  return (
    <>
      <FooterStyles>
        <div className="symbol">
          <img src={symbol} alt="" />
        </div>
        <div className="wrapper">
          <div className="footerlinks">WORLD OF WHISKEY 2024</div>
          <div className="footersocials">INFO@WORLDOFWHISKEY.IN</div>
        </div>
      </FooterStyles>
    </>
  )
}
